<template>
<ul class="categories-list">
  <categories-bar-items
      v-for="category in categoriesList"
      :key="category.label"
      :title="category.title"
      :label="category.label"
      :class="{'categories-list-item--active' : selectedCategoryLabel === category?.label}"
      :style="{width : categoryItemsWidth}"
      @category-selected="selectCategory"
  >

  </categories-bar-items>
</ul>
</template>

<script>
import CategoriesBarItems from "./CategoriesBarItems";
export default {
  name: "CategoriesBar",
  components: {CategoriesBarItems},
  props : ['categoriesList', 'selectedCategoryLabel'],
  emits:['category-selected'],
  data(){
    return {
      categoryItemsWidth : innerWidth/4 + 'px',
      // selectedCategoryLabel: this.categoriesList[0]?.label
    }
  },
  methods: {
    selectCategory(categoryLabel){
      this.$emit('category-selected', categoryLabel)
    }
  }

}
</script>

<style scoped>
.categories-list{
  white-space: nowrap;
  overflow-x: auto;
  height: 10rem;
  border-bottom: .1rem solid #C1DBCD;

}
.categories-list-item--active{
  background: radial-gradient(#daede1,#69b38a)
}
</style>