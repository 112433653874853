<template>
<router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.$store.dispatch('makeImageComponents');
  }
}
</script>

<style>
@import url("./assets/fonts/YekanFont/css/fontiran.css");

* {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  font-family: inherit;
}
ul{
  list-style: none;
}
html {
  font-size: 62.5%;
  font-family: iranyekan, roboto, Arial;
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow:hidden;
  position: fixed;
}
#app {
  width: 100%;
  height: 100%;
  background-color: #FCF8F6;
}
.category-item__image{
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 1rem;

  box-shadow: 0 1rem 2rem rgba(0,0,0,.26);
}
.categories-list-item__icon > img {
  width: 100%;
  height: 100%;
}
@media (orientation: portrait) {
  .category-item__image--previewed{
    width: 80%;
    aspect-ratio: 1/1;
    border-radius: 1rem;

    box-shadow: 0 1rem 2rem rgba(0,0,0,.26);
  }
}
@media (orientation: landscape) {
  .category-item__image--previewed{
    height : 80%;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    box-shadow: 0 1rem 2rem rgba(0,0,0,.26);
  }
}
</style>
