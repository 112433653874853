<template>
<div class="landing-page">
  <section class="logo-and-welcome">
    <div class="logo-and-welcome__logo">
      <base-logo></base-logo>
    </div>
    <div class="logo-and-welcome__welcome">به اُوان خوش آمدید</div>
  </section>
<!--  <section class="ladies-night">-->
<!--    <div class="ladies-night__text">-->
<!--      <span>در روزهای یکشنبه و سه شنبه، </span>-->
<!--      <span>۲۰٪ تخفیف </span>-->
<!--      <br>-->
<!--      <span>برای </span>-->
<!--      <span>بانوان </span>-->
<!--      <span>بر روی نوشیدنی ها</span>-->
<!--    </div>-->
<!--  </section>-->
  <section class="show-menu">
    <base-button
        :is-active="true"
        @click="navigateToMenu"
        :continuous-params="[
          'color=white','background-color=#69B38A'
          ]"
        :style-types="[
            'button--curved',]"
        :button-inner-txt="'مشاهده منو'"
    ></base-button>
  </section>
</div>
</template>

<script>
import BaseLogo from "../components/UI/BaseLogo";
import BaseButton from "../components/UI/BaseButton";
export default {
  name: "LandingPage",
  components: {BaseLogo, BaseButton},
  methods: {
    navigateToMenu(){
      this.$router.push({name: 'menu'});
    }
  }
}
</script>

<style scoped>
.landing-page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-and-welcome{
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
/*.ladies-night{*/
/*  width: 100%;*/
/*  height: 15%;*/
/*  font-weight: bold;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  font-size: 2rem;*/
/*  text-shadow: rgba(0,0,0,.26) 0.1rem 0 0.4rem*/
/*}*/
/*.ladies-night__text{*/
/*  text-align: center;*/
/*}*/
/*.ladies-night__text > span:nth-child(2){*/
/*  color: #69B38A;*/
/*}*/
/*.ladies-night__text > span:nth-child(5){*/
/*  color: #69B38A;*/
/*}*/
/*.logo-and-welcome__welcome{*/
/*  font-size: 1.2rem;*/
/*}*/
.show-menu{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (orientation: portrait) {
  .logo-and-welcome__logo{
    width: 60%;
    aspect-ratio: 1/1;
  }
}
@media (orientation: landscape) {
  .logo-and-welcome__logo{
    height: 60%;
    aspect-ratio: 1/1;
  }
}
</style>