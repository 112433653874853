<template>
<li class="category-item-fragment" @click="showItemPreview">
  <div class="category-item">
  <div ref="item">

  </div>
  <div>
    <div class="category-item__title">{{title}}</div>
    <div class="category-item__price">{{price}}</div>
  </div>
  </div>
</li>
</template>

<script>
export default {
  name: "CategoryListItem",
  props: ['categoryLabel', 'label', 'title', 'price', 'description'],
  emits: ['item-clicked'],
  data(){
    return {
    }
  },
  methods: {
    showItemPreview(){
      this.$emit('item-clicked', this.label);
    }
  },
  mounted() {
    const imageEl = this.$store.getters.menu.get(this.categoryLabel).items.find(item => item.label === this.label)?.imageEl;
    if(imageEl){
      const clonedImageEl = imageEl.cloneNode();
      this.$refs.item.insertAdjacentElement('afterbegin', clonedImageEl)
    } else {
      const div = document.createElement('section');
      div.style.width = '100%';
      div.style.aspectRatio = '1/1';
      div.style.backgroundColor = 'transparent';
      this.$refs.item.insertAdjacentElement('afterbegin', div)

    }
  }
}
</script>

<style scoped>
.category-item-fragment{
  width: 50%;
  aspect-ratio: 1/1;
  background-color: #c1dbcd;
  display: flex;
  border-bottom: .1rem solid #C1DBCD;
}
.category-item{
  width: 100%;
  height: 100%;
  background-color: #fcf8f6;
  display: flex;
  flex-direction: column;
  clip-path: polygon(10% 0,90% 0,100% 10%,100% 90%,90% 100%,10% 100%,0 90%,0 10%);
  justify-content: space-evenly;
  align-items: center;
}
.category-item-fragment:nth-child(odd){
  border-right: .1rem solid #C1DBCD;
}
.category-item > div:nth-child(1) {
  width: 55%;
}
.category-item > div:nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.category-item__title{
  font-size: 1.3rem;
  font-weight: bold;
  text-shadow: rgba(0,0,0,.26) 0.1rem 0 0.4rem
}
.category-item__price{
  font-size: 1.2rem;
  text-shadow: rgba(0,0,0,.26) 0.1rem 0 0.4rem;
}
</style>