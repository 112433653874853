import menu from '../../../menu';
export default {
    state: {
        menu : menu
    },
    mutations:{
        MAKE_IMAGE_COMPONENTS: state => {
            state.menu.forEach(category => {
              const iconImg = new Image();
              iconImg.alt = 'image is not loaded';
              iconImg.src = category.iconAddr;
              category.iconEl = iconImg;
              for(let i = 0; i < category.items.length; ++i){
                  if(category.items[i].imageAddr) {
                      const img = new Image();
                      img.classList.add('category-item__image');
                      img.alt = 'image is not loaded';
                      img.src = category.items[i].imageAddr;
                      category.items[i].imageEl = img;
                  }
              }
            });
        },
    },

    actions:{
        makeImageComponents({ commit }) {
            commit('MAKE_IMAGE_COMPONENTS');
        },
    }
}