<template>
<li class="categories-list-item" @click="selectCategory">
  <div>
    <div class="categories-list-item__icon" ref="category-icon">
    </div>
    <div class="categories-list-item__title">{{ title }}</div>
  </div>
</li>
</template>

<script>
export default {
  name: "CategoryItems",
  props: ['label', 'title'],
  emits: ['category-selected'],
  methods: {
    selectCategory(){
      this.$emit('category-selected', this.label);
    },
  },
  mounted(){
    const item = [...this.$store.getters.menu.values()].find(item => item?.label === this.label);
    this.$refs['category-icon'].appendChild(item?.iconEl);
  }
}
</script>

<style scoped>
.categories-list-item{
  height: 100%;
  display: inline-block;
  border-right: .1rem solid #C1DBCD;
}
.categories-list-item:last-child{
  border: none;
}
.categories-list-item > div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categories-list-item__icon{
  height: 50%;
  aspect-ratio: 1/1;
  margin-bottom: 0.5rem;
}

</style>