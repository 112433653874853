import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from "../views/LandingPage";
import MenuPage from "../views/MenuPage";
const history = createWebHistory();
const routes = [
    {
        path: '/',
        component: LandingPage,
        meta: {
            public: true,
        }
    },
    {
        path: '/menu',
        component: MenuPage,
        name: 'menu',
        meta: {
            public: true,
        }
    },
]

const router = createRouter({
    history,
    routes,
});

export default router;


