<template>
<ul class="category-items">
  <category-list-item
      v-for="(item, idx) in categoryItems"
      :key="item.label"
      :category-label="categoryLabel"
      :label="item.label"
      :title="item.title"
      :price="item.price"
      :description="item.desc"
      :style="{borderLeft: (idx % 2 === 0 && idx === categoryItems.length - 1)? '.1rem solid #C1DBCD': 'none'}"
      @item-clicked="$emit('item-clicked', $event)"
  ></category-list-item>
</ul>
</template>

<script>
import CategoryListItem from "./CategoryListItem";
export default {
  name: "CategoryList",
  props: ['categoryLabel', 'categoryItems'],
  emits : ['item-clicked'],
  components: {CategoryListItem}
}
</script>

<style scoped>
.category-items{
  width: 100%;
  max-height: calc(100% - 10rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
}
</style>