<template>
  <img src="/img/ovan.png" alt="logo is not loaded">
</template>

<script>
export default {
  name: "BaseLogo"
}
</script>

<style scoped>
img{
  width: 100%;
  height: 100%;
}
</style>