<template>
<div class="menu-page">
  <div class="item-preview" @click="hideItemPreview" v-if="isPreviewShown">
    <div ref="item-preview">
      <div class="item-preview__description">{{clickedItemDescription}}</div>
    </div>
  </div>
  <categories-bar
      :categories-list="[...menu.values()]"
      :selected-category-label="selectedCategory?.label"
      @category-selected="getSelectedCategoryLabel">

  </categories-bar>
  <category-list
      :category-items="selectedCategory?.items"
      :category-label="selectedCategory?.label"
      @item-clicked="showItemPreview"
  >
  </category-list>
</div>
</template>

<script>
import menu from '../menu';
import CategoriesBar from "../components/CategoriesBar";
import CategoryList from "../components/CategoryList";
import {nextTick} from "vue";
export default {
  name: "MenuPage",
  components: {CategoryList, CategoriesBar},
  data(){
    return {
      menu: menu,
      isPreviewShown : false,
      clickedItemDescription: '',
      selectedCategory: {label: [...menu?.values()][0]?.label, items: [...menu?.values()][0]?.items}
    }
  },
  methods: {
    getSelectedCategoryLabel(label){
      if(menu.has(label)){
        this.selectedCategory.label = label;
        this.selectedCategory.items = [...this.$store.getters.menu?.values()].find(item => item.label === label)?.items;
      } else {
        this.menu = new Map()
      }
    },
    showItemPreview(label){
      this.isPreviewShown = true;
      const selectedItem = this.selectedCategory.items.find(item => item?.label === label);
      this.clickedItemDescription = selectedItem?.desc;
      const clonedImageEl = selectedItem?.imageEl.cloneNode();
      clonedImageEl.classList.remove('category-item__image');
      clonedImageEl.classList.add('category-item__image--previewed');
      nextTick(() => {
        this.$refs['item-preview'].insertAdjacentElement('afterbegin', clonedImageEl)
      })
    },
    hideItemPreview(){
      this.isPreviewShown = false;
    }
  }
}
</script>

<style scoped>
.menu-page{
  width: 100%;
  height: 100%;
  position: relative;
}
.item-preview{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(252, 248, 246, .9);
  top: 0;
  left: 0;
  z-index: 1;
}
.item-preview > div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item-preview__description{
  width: 95%;
  border-radius: .4rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #69b38a;
  text-align: center;
  text-shadow: #c1dbcd 0.1rem 0 0.4rem;
}
</style>